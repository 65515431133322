import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class Navigation {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-nav',
            rootToggleAttr: 'rootToggle',
            childrenAttr: 'category',
            scrollerAttr: 'scroller',
            subScrollerAttr: 'sub-scroller',
            toggleAttr: 'toggle',
            closeAttr: 'close',
            a11yLabelAttr: 'data-for',
            breakpoint: 1024
        };

        this.settings = Object.assign({}, defaults, options);

        this.$navigation = element;
        this.$navigationToggle = document.querySelector('[' + this.settings.initAttr + '="' + this.settings.rootToggleAttr + '"]');
        this.$navigationScroller = this.$navigation.querySelector('[' + this.settings.initAttr + '="' + this.settings.scrollerAttr + '"]');
        this.$navigationToggles = this.$navigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.toggleAttr + '"]');
        this.$navigationMainWithChildren = this.$navigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.childrenAttr + '"]');
        this.$navigationA11yLabels = this.$navigation.querySelectorAll('[' + this.settings.a11yLabelAttr + ']');
        this.$body = document.querySelector('body');
        this.closeTimer = null;
        this.focusedRootElement = null;
        this.focusTimer = null;

        this.initialize();
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        const breakpoint = window.matchMedia('(min-width: ' + this.settings.breakpoint + 'px)');

        this.$navigation.addEventListener('change', (e) => {
            const $element = e.target;

            if ($element === this.$navigationToggle) {
                if (this.$navigationToggle.checked === true) {
                    this.clearAllSubnavs();
                    disableBodyScroll(this.$navigationScroller);
                } else {
                    enableBodyScroll(this.$navigationScroller);
                }
            }

            if ($element.getAttribute('data-nav') === 'toggle') {
                const $parent = $element.closest('[data-nav="category"]');
                if ($parent) {
                    if ($element.checked === true) {
                        $parent.setAttribute('aria-expanded', true);
                    } else {
                        $parent.setAttribute('aria-expanded', false);
                    }
                }
            }

            if ($element.getAttribute(this.settings.initAttr) === this.settings.toggleAttr) {
                this.$navigationScroller.scrollTo(0, 0);
            }
        });

        // enter fires change event
        this.$navigation.addEventListener('keydown', (e) => {
            let isEnter = false;
            if ('key' in e) {
                isEnter = (e.key === 'Enter');
            } else {
                isEnter = (e.keyCode === 13);
            }

            if (isEnter) {
                const $element = e.target;
                if ($element.tagName.toLowerCase() === 'input' && $element.type === 'checkbox' && document.activeElement === $element) {
                    this.toggleInputChecked($element, !$element.checked);
                }
            }
        });

        this.$navigationA11yLabels.forEach($a11yLabel => {
            $a11yLabel.addEventListener('click', () => {
                const forAttr = $a11yLabel.getAttribute(this.settings.a11yLabelAttr);

                this.toggleInputChecked(document.getElementById(forAttr), false);
            });
        });

        for (let i = 0; i < this.$navigationMainWithChildren.length; i++) {
            const $rootItem = this.$navigationMainWithChildren[i];
            const $closeSubnavHandle = $rootItem.querySelector('[' + this.settings.initAttr + '="' + this.settings.closeAttr + '"]');
            const $subScroller = $rootItem.querySelector('[' + this.settings.initAttr + '="' + this.settings.subScrollerAttr + '"]');

            $rootItem.addEventListener('mouseenter', () => {
                if (breakpoint.matches) {
                    this.$body.classList.add('nav--open');
                    $rootItem.setAttribute('aria-expanded', true);
                    $subScroller.scrollTo(0, 0);
                    disableBodyScroll($subScroller);
                }
            });

            $rootItem.addEventListener('mouseleave', () => {
                if (breakpoint.matches) {
                    $rootItem.classList.remove('focus-within');
                    this.$body.classList.remove('nav--open');
                    $rootItem.setAttribute('aria-expanded', false);
                    this.clearAllSubnavs();
                    enableBodyScroll($subScroller);
                }
            });

            $rootItem.addEventListener('focusin', () => {
                this.focusedRootElement = $rootItem;
                if (breakpoint.matches) {
                    $rootItem.setAttribute('aria-expanded', true);
                    this.closeOtherSubnavs($rootItem);
                    clearTimeout(this.focusTimer);
                }
            });

            $rootItem.addEventListener('focusout', () => {
                if (breakpoint.matches) {
                    $rootItem.setAttribute('aria-expanded', false);
                }
                clearTimeout(this.focusTimer);
                this.focusTimer = window.setTimeout(() => {
                    this.focusedRootElement = null;
                }, 500);
            });

            $closeSubnavHandle.addEventListener('click', (e) => {
                const targetId = $closeSubnavHandle.getAttribute('for');
                const $target = document.getElementById(targetId);

                if (breakpoint.matches) {
                    this.$body.classList.remove('nav--open');
                    this.closeMainnav($rootItem);

                    if ($target && $target.checked === false) {
                        e.preventDefault();
                    }
                }
            });
        }

        document.addEventListener('click', (e) => {
            if (breakpoint.matches && e.target.classList.contains('main-nav--level-2')) {
                const $rootItem = e.target.closest('[' + this.settings.initAttr + '="' + this.settings.childrenAttr + '"]');
                this.closeMainnav($rootItem);
            }
        });

        window.addEventListener('resize', () => {
            if (!window.isMobileDevice) {
                this.$navigationToggle.checked = false;
                enableBodyScroll(this.$navigationScroller);
            }
        });

        // close navigation on ESC key
        document.addEventListener('keydown', (e) => {
            let isEscape = false;
            // let isLeft = false;
            // let isRight = false;
            let isUp = false;
            let isDown = false;

            if ('key' in e) {
                isEscape = (e.key === 'Escape' || e.key === 'Esc');
                // isLeft = (e.key === 'ArrowLeft');
                // isRight = (e.key === 'ArrowRight');
                isUp = (e.key === 'ArrowUp');
                isDown = (e.key === 'ArrowDown');
            } else {
                isEscape = (e.keyCode === 27);
                // isLeft = (e.keyCode === 37);
                // isRight = (e.keyCode === 39);
                isUp = (e.keyCode === 38);
                isDown = (e.keyCode === 40);
            }

            if (isEscape) {
                if (breakpoint.matches) {
                    this.closeMainnav(this.focusedRootElement);
                    if (this.focusedRootElement) {
                        this.focusedRootElement.classList.remove('focus-within');
                    }
                } else {
                    this.clearAllSubnavs();
                    this.toggleInputChecked(this.$navigationToggle, false);
                }
            }

            if (breakpoint.matches) {
                if (this.focusedRootElement !== null) {
                    if (isDown) {
                        this.focusedRootElement.classList.add('focus-within');
                    }
                    if (isUp) {
                        this.focusedRootElement.classList.remove('focus-within');
                    }
                }
            }
        });
    }

    toggleInputChecked ($input, state = true) {
        $input.checked = state;

        const toggleInputCheckedEvent = new CustomEvent('change', {
            view: window,
            bubbles: true,
            cancelable: false
        });

        $input.dispatchEvent(toggleInputCheckedEvent);
    }

    blurElement ($item) {
        const mouseLeaveEvent = new CustomEvent('mouseleave', {
            view: window,
            bubbles: true,
            cancelable: false
        });

        $item.dispatchEvent(mouseLeaveEvent);
    }

    closeMainnav ($item) {
        clearTimeout(this.closeTimer);

        if ($item) {
            $item.classList.add('inactive');

            this.blurElement($item);

            this.closeTimer = window.setTimeout(() => {
                $item.classList.remove('inactive');

                $item.querySelector('a').focus();
            }, 500);
        }
    }

    closeOtherSubnavs ($item) {
        this.$navigationMainWithChildren.forEach($parent => {
            if ($parent !== $item) {
                $parent.classList.remove('focus-within');
            }
        });
    }

    clearAllSubnavs () {
        for (let i = 0; i < this.$navigationToggles.length; i++) {
            const $toggle = this.$navigationToggles[i];

            if ($toggle.checked === true) {
                $toggle.checked = false;
            }
        }
    }
}

export default Navigation;
